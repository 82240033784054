import React from "react";
import "./lists.css";
import ApiService from "../../../../services/ApiService";
import { toast } from "react-toastify";
import { createData } from "./utils";
import { GuestTableFilters } from "./GuestTableFilters";
import LinearLoader from "../../components/LinearLoader";
import { FormDialog } from "../../components/FormDialog";

import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { StatBoxWithTitle } from "../../components/StatBoxWithTitle";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { CustomTable } from "../../../../common/table_component/CustomTable";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import { hasGuestBeenChecked } from "../../../../checkin/new/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "red",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "#e1dfdf",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Nom et prénom",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: false,
    label: "Langue",
  },
  {
    id: "society",
    numeric: false,
    disablePadding: false,
    label: "Société",
  },
  {
    id: "mypackage",
    numeric: false,
    disablePadding: false,
    label: "Packages",
  },
  {
    id: "response",
    numeric: false,
    disablePadding: false,
    label: "Réponse",
  },
  {
    id: "checkin",
    numeric: false,
    disablePadding: false,
    label: "Checkin",
  },
];

export function Lists({
  open,
  handleDialogClose,
  updateParentLists,
  guests,
  listId,
  packageOptions,
  tagsOptions,
  countryOptions,
  componentsOptions,
  eventComponents,
  evenement,
}) {
  const [title, setTitle] = React.useState("");
  const [filteredGuests, setFilteredGuests] = React.useState(guests);
  const [eventComponentsResponses, setEventComponentsResponses] =
    React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const filtersValues = React.useRef({
    nameFilter: "",
    companyFilter: "",
    languageFilter: "",
    countryFilter: "",
    packageFilter: "",
    followerFilter: "",
    documentFilter: "",
    tagsFilter: [],
    responseFilter: "",
    componentFilter: "",
    componentResponse: "",
  });

  const guestResponseStates = [
    {
      label: "OUI",
      value: "willJoin",
      color: "#B3E9E9",
    },
    {
      label: "NON",
      value: "willNotJoin",
      color: "#FEC784",
    },
    {
      label: "-",
      value: "waiting",
      color: "transparent",
    },
    {
      label: "EN ATTENTE DE VALIDATION",
      value: "pending",
      color: "#FFF79A",
    },
  ];

  const generateTableReponse = (guest) => {
    if (!guest.is_validated && evenement.data.free === "false") {
      return "pending";
    } else if (guest.participate) {
      return "willJoin";
    } else if (
      eventComponentsResponses.some((reponse) => reponse.guest_id === guest.id)
    ) {
      return "willNotJoin";
    } else return "waiting";
  };

  const createGuestsTableRows = (data) => {
    return data.map((guest, index) => {
      let companyName = "";
      let guestLanguage = "";
      if (guest.contact) {
        companyName = guest.contact.company ? guest.contact.company.name : "";
        guestLanguage = guest.contact.language ? guest.contact.language : "";
      }
      let guestPackage = packageOptions.find(
        (pck) => pck.value === guest.package_id
      );
      guestPackage = guestPackage ? guestPackage.label : "";

      let guestResponse = generateTableReponse(guest);
      const stateFound = guestResponseStates.find(
        (state) => state.value === guestResponse
      );
      guestResponse = stateFound ? stateFound.label : "";

      let hasBeenCheckedIn = hasGuestBeenChecked(guest);

      return createData(
        guest.id,
        guest.firstname + " " + guest.lastname,
        guestLanguage,
        companyName,
        guestPackage,
        guestResponse,
        hasBeenCheckedIn
      );
    });
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      if (listId) {
        const res = await ApiService.request(
          {},
          "guests_list/" + listId,
          "get"
        );
        if (res) {
          setTitle(res.title);
          filtersValues.current = res.filters_values;
          setSelectedGuests(res.guests_ids);
        }
      }

      //! Find alternative, I think getting form is not necessary
      const dataForm = await ApiService.awaitRequest(
        {},
        "evenement_forms/byevenement/" + evenement.id,
        "get"
      );
      const form = dataForm.find(
        (form) => form.layout.documentType === "reponse"
      );

      if (form) {
        await ApiService.awaitRequest(
          {},
          "component_responses/byevenement/" + form.id,
          "get"
        ).then(function (data) {
          setEventComponentsResponses(data);
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setDisableSaveButton(true);
      if (listId) {
        const res = await ApiService.request(
          {
            title,
            guests_ids: selectedGuests,
            filters_values: filtersValues.current,
          },
          "guests_list/" + listId,
          "put"
        );
        updateParentLists(res, "list");
        toast.success("Liste d'invités sauvegardées");
      } else {
        const res = await ApiService.request(
          {
            evenement_id: evenement.id,
            title,
            guests_ids: selectedGuests,
            filters_values: filtersValues.current,
          },
          "guests_list",
          "post"
        );
        updateParentLists(res, "list");
        toast.success("Liste d'invités crée");
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      handleDialogClose();
      setDisableSaveButton(false);
    }
  };

  const populateRows = (isSelected, handleClick, visibleRows) => {
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <StyledTableRow
          key={row.id}
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
          sx={{ cursor: "pointer" }}
        >
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
                style: { width: "0px" },
              }}
              sx={{
                color: "#626262",
                "&.Mui-checked": {
                  color: "#d81b60",
                },
              }}
            />
          </StyledTableCell>
          <StyledTableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
          >
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="left">{row.language}</StyledTableCell>
          <StyledTableCell align="left">{row.society}</StyledTableCell>
          <StyledTableCell align="left">{row.mypackage}</StyledTableCell>
          <StyledTableCell align="left">{row.response}</StyledTableCell>
          <StyledTableCell align="left">
            {row.checkin ? "true" : "false"}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  React.useEffect(() => {
    loadData();
  }, [listId]);

  return (
    <div className="list_wrapper">
      <FormDialog
        open={open}
        handleClose={handleDialogClose}
        onSubmit={handleSubmit}
        className="list_form"
      >
        <DialogContent
          sx={{
            minWidth: "870px",
            minHeight: "400px",
          }}
        >
          {isLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "inherit",
              }}
            >
              <LinearLoader width={"35%"} />
            </div>
          ) : (
            <>
              <div className="list_header">
                <div className="list_name">
                  <h4>Nom de la liste</h4>
                  <div>
                    <TextField
                      id="outlined-basic-list-title"
                      variant="outlined"
                      required
                      size="small"
                      value={title}
                      onChange={({ target: { value } }) => setTitle(value)}
                      sx={{
                        maxWidth: "450px",
                        minWidth: "300px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          color: "#000000",
                          borderRadius: "0.7rem",
                          "& input:focus": {
                            borderBottom: 0,
                            boxShadow: "none",
                          },
                        },
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          ":hover": {
                            boxShadow: "none",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000 !important",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#000000 !important",
                          fontSize: "0.9em",
                        },
                      }}
                    />
                    <p className="helperText">
                      Choisissez un nom explicatif et différencié.
                    </p>
                  </div>
                </div>
                <IconButton onClick={handleDialogClose}>
                  <CloseIcon className="list-dialog-close-btn" />
                </IconButton>
              </div>
              <div className="list-main-content">
                <GuestTableFilters
                  guests={guests}
                  setFilteredGuests={setFilteredGuests}
                  componentsOptions={componentsOptions}
                  countryOptions={countryOptions}
                  packageOptions={packageOptions}
                  tagsOptions={tagsOptions}
                  eventComponentsResponses={eventComponentsResponses}
                  components={eventComponents}
                  filtersValues={filtersValues}
                />
                {filteredGuests.length && (
                  <div style={{ padding: "0.68rem" }}>
                    <CustomTable
                      data={filteredGuests}
                      createTableRows={createGuestsTableRows}
                      selected={selectedGuests}
                      setSelected={setSelectedGuests}
                      populateRows={populateRows}
                      headCells={headCells}
                      // headCellesNoWrap={true}
                      activateCheckbox={true}
                    />
                  </div>
                )}
              </div>
              <StatBoxWithTitle
                title="Destinataires"
                stat={selectedGuests.length}
                elevation={6}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CustomMuiButton
            type="submit"
            text="Enregistrer"
            minHeight={undefined}
            textColor="#ffffff"
            buttonBgColor="#E01755"
            buttonHoverBgColor="#AF1242"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#E01755"
            disable={disableSaveButton}
          />
        </DialogActions>
      </FormDialog>
    </div>
  );
}
