import React from "react";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import SmsPreview from "./SmsPreview";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import PaidIcon from "@mui/icons-material/Paid";
import "./SmsForm.css";

const STOP_SMS_TEXT = "\nSTOP SMS";

export function SmsCampaignStepOne({
  campaignTitle,
  setCampaignTitle,
  senderName,
  setSenderName,
  message,
  setMessage,
  stopSMS,
  setStopSMS,
  isSmsCampaignEditable,
  shortcodesOptions,
  setStep,
  submitCampaign,
}) {
  const [disableDraftButton, setDisableDraftButton] = React.useState(false);

  const formatTextWithLineBreaks = (text) => {
    const lines = text.split("\n");

    const formattedText = lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));

    return formattedText;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.nativeEvent.submitter.name === "draft") {
      try {
        setDisableDraftButton(true);
        submitCampaign("draft");
      } catch (error) {
        console.log(error);
      } finally {
        setDisableDraftButton(false);
      }
    } else if (e.nativeEvent.submitter.name === "next") {
      setStep(2);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            type="text"
            name="title"
            className="form-control"
            value={campaignTitle}
            onChange={(e) => setCampaignTitle(e.target.value)}
            required
            placeholder="Nom*"
            disabled={!isSmsCampaignEditable()}
          />
        </div>
        <div className="form-group col-md-6">
          <input
            type="text"
            name="sender_name"
            className="form-control"
            value={senderName}
            maxLength={11}
            minLength={3}
            onChange={(e) => {
              const value = e.target.value;
              if (value && value.trim().length > 0) {
                setStopSMS(false);
              }
              setSenderName(e.target.value);
            }}
            placeholder="Expéditeur"
            disabled={!isSmsCampaignEditable()}
          />
          <small>
            La personnalisation de l'expéditeur n'est pas autorisé dans tous les
            pays. Le cas échéant, le nom sera remplacé par un numéro de type 36
            XX
          </small>
          {/* )} */}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="d-flex justify-content-between mb-0 pb-1">
            <p className="font-weight-bold m-0">Message</p>
            <div className="col-md-6 d-flex flex-end pr-3">
              <select
                autoFocus={false}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value !== "") setMessage((prev) => prev + value);
                }}
                name="sms-message-shortcodes"
                className="browser-default sms-shortcodes-select"
                value={""}
              >
                <option key={`sms-message-shortcodes-i0`} value="">
                  Personnalisation
                </option>
                {shortcodesOptions.map((elem, index) => (
                  <option
                    key={`sms-message-shortcodes-i${index}`}
                    value={elem.value}
                  >
                    {elem.label}
                  </option>
                ))}
              </select>
            </div>
          </label>
          <textarea
            type="text"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            className="form-control"
            rows="5"
            value={message}
            disabled={!isSmsCampaignEditable()}
            required
          />
          <div className="row justify-content-between mt-2">
            <div className="d-flex justify-content-end col-md-12 pt-2">
              <p className="m-0">
                Nb Caractères{" "}
                <span
                  style={{
                    color: stopSMS
                      ? message.length + STOP_SMS_TEXT.length <= 130
                        ? "#212529"
                        : "red"
                      : message.length <= 130
                      ? "#212529"
                      : "red",
                  }}
                >
                  {stopSMS
                    ? message.length + STOP_SMS_TEXT.length
                    : message.length}
                </span>
                /160
              </p>
            </div>
            <div className="col-md-12 pt-2">
              <p className="m-0 indication-text">
                Si vous utilisez la personnalisation, le nombre de caractères
                est estimatif.
              </p>
              <p className="indication-text">
                Si le message personnalisé dépasse les 160 caractères, il sera
                envoyé en 2 textos.
              </p>
            </div>
            <div className="col-md-12">
              <FormGroup>
                <FormControlLabel
                  className="mb-0"
                  control={
                    <Checkbox
                      checked={stopSMS}
                      onChange={(e) => setStopSMS(e.target.checked)}
                      sx={{
                        color: "#626262",
                        "&.Mui-checked": {
                          color: "#d81b60",
                        },
                        '&.row-checkbox-pj [type="checkbox"]:not(:checked), [type="checkbox"]:checked':
                          {
                            position: "absolute !important",
                          },
                      }}
                      disabled={
                        !isSmsCampaignEditable() ||
                        (senderName && senderName.trim().length > 0)
                      }
                    />
                  }
                  label="STOP SMS (7 caractères)"
                />
                <div className="col-md-12">
                  <p className="m-0 pl-3 indication-text">
                    Obligatoire uniquement en cas de prospection/publicitaire.
                  </p>
                  <p className="pl-3 indication-text">
                    Incompatible avec la personnalisation de l’expéditeur.
                  </p>
                </div>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="form-group col-md-6 mt-3">
          <SmsPreview
            message={formatTextWithLineBreaks(
              stopSMS ? message + STOP_SMS_TEXT : message
            )}
          />
        </div>
        <div className="form-row col-md-12">
          <div className="col-md-6 d-flex align-items-end">
            <a
              href="/kanguroo_SMS_prices_2023b.pdf"
              target="_blank"
              className="tarif-sms"
            >
              <p>TARIFS</p>
              <PaidIcon className="money-icon" />
            </a>
          </div>
          <div className="form-group col-md-6 d-flex justify-content-center">
            <div style={{ marginRight: "0.5rem" }}>
              <CustomMuiButton
                type="submit"
                name="draft"
                text={
                  <>
                    ENREGISTRER <br /> BROUILLON
                  </>
                }
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#A2A2A2"
                buttonHoverBgColor="#7A7A7A"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#A2A2A2"
                disable={disableDraftButton}
              />
            </div>
            <div style={{ marginLeft: "0.5rem" }}>
              <CustomMuiButton
                type="submit"
                name="next"
                text="SUIVANT"
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#E01755"
                buttonHoverBgColor="#AF1242"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#E01755"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
