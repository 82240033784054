import axios from "axios";
import { clearLocalStorage, guestMode } from "./Utils";

class ApiService {
  constructor() {
    switch (true) {
      // PROD
      case window.location.href.includes("epcr.app.kanguroo-event.fr"):
      case window.location.href.includes("invitations.epcrugby.com"):
        this.apiUrl = "https://epcr.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("app.participer-evenements.fr"):
      case window.location.href.includes("stellium.app.kanguroo-event.fr"):
        this.apiUrl = "https://stellium.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("veolia.app.kanguroo-event.fr"):
        this.apiUrl = "https://veolia.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("purevents.app.kanguroo-event.fr"):
        this.apiUrl = "https://purevents.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("digeek.app.kanguroo-event.fr"):
        this.apiUrl = "https://digeek.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("lnr.app.kanguroo-event.fr"):
      case window.location.href.includes("app.lnr-protocole.fr"):
        this.apiUrl = "https://lnr.api.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("test.app.kanguroo-event.fr"):
        this.apiUrl = "https://test.api.dev.kanguroo-event.fr/api/";
        break;
      // DEV
      case window.location.href.includes("localhost:3000"):
      case window.location.href.includes("localhost:3001"):
      case window.location.href.includes("localhost:3003"):
      case window.location.href.includes("localhost:3002"):
      case window.location.href.includes("192.168"):
        // this.apiUrl = "https://stellium.api.dev.kanguroo-event.fr/api/";
        // this.apiUrl = "https://stellium.api.kanguroo-event.fr/api/";
        // this.apiUrl = "https://epcr.api.dev.kanguroo-event.fr/api/";
        // this.apiUrl = "https://epcr.api.kanguroo-event.fr/api/";
        // this.apiUrl = "https://lnr.api.kanguroo-event.fr/api/";
        // this.apiUrl = "https://lnr.api.dev.kanguroo-event.fr/api/";
        // this.apiUrl = "http://127.0.0.1:8000/api/";
        this.apiUrl = "https://lnr.api.kanguroo-event.fr/api/";
        // this.apiUrl = "https://digeek.api.dev.kanguroo-event.fr/api/";
        // this.apiUrl = "https://digeek.api.kanguroo-event.fr/api/";
        // this.apiUrl = "https://test.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("epcr.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://epcr.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("digeek.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://digeek.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("test.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://test.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("stellium.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://stellium.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("veolia.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://veolia.api.dev.kanguroo-event.fr/api/";
        break;
      case window.location.href.includes("app.dev.kanguroo-event.fr"):
      case window.location.href.includes("lnr.app.dev.kanguroo-event.fr"):
        this.apiUrl = "https://lnr.api.dev.kanguroo-event.fr/api/";
        break;
      default:
        throw new Error("Cannot detect corresponding API");
    }
  }

  async awaitRequest(data, url, method, fileType, contentType, responseType) {
    let headers = {};
    if (localStorage.getItem("token") && !guestMode()) {
      headers = {
        Accept: "application/json",
        "Content-Type": contentType
          ? contentType
          : fileType
          ? "multipart/form-data"
          : "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    } else if (localStorage.getItem("invitation_token") && guestMode()) {
      headers = {
        Accept: "application/json",
        "Content-Type": contentType
          ? contentType
          : fileType
          ? "multipart/form-data"
          : "application/json",

        "X-Auth-By-Token": localStorage.getItem("invitation_token"),
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": contentType
          ? contentType
          : fileType
          ? "multipart/form-data"
          : "application/json",
      };
    }
    let response = await axios({
      headers,
      method,
      withCredentials: false,
      url: this.apiUrl + url,
      data,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      timeout: 0,
      responseType,
    }).catch((e) => {
      return false;
    });

    if (
      localStorage.getItem("token") &&
      response.data &&
      response.data.message
    ) {
      const message = response.data.message;
      if (
        message === "error:invalid_token" ||
        message === "error:expired_token" ||
        message === "error:token_not_found"
      ) {
        clearLocalStorage();

        window.location.replace(
          window.location.href.includes("checkin") ? "/checkin/" : "/admin/"
        );
      }
    }

    return response.data;
  }

  async request(data, url, method, fileType) {
    let headers = {};

    if (localStorage.getItem("token") && !guestMode()) {
      headers = {
        Accept: "application/json",

        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    } else if (localStorage.getItem("invitation_token") && guestMode()) {
      headers = {
        Accept: "application/json",

        "Content-Type": fileType ? "multipart/form-data" : "application/json",

        "X-Auth-By-Token": localStorage.getItem("invitation_token"),
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    const pending = await axios({
      headers,
      method,
      credentials: false,
      url: this.apiUrl + url,
      data,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 0,
    });

    const response = await pending;
    if (
      localStorage.getItem("token") &&
      response.data &&
      response.data.message
    ) {
      const message = response.data.message;
      if (
        message === "error:invalid_token" ||
        message === "error:expired_token" ||
        message === "error:token_not_found"
      ) {
        clearLocalStorage();

        window.location.replace(
          window.location.href.includes("checkin") ? "/checkin/" : "/admin/"
        );
      }
    }

    return response.data;
  }

  checkToken() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }
}

export default new ApiService();
