import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import moment from "moment-timezone";

import fr from "date-fns/locale/fr";
import DatePicker, { registerLocale } from "react-datepicker";
import "./SmsForm.css";
import { CustomMuiButton } from "../../components/CustomMuiButton";

registerLocale("fr", fr);

export function SmsCampaignStepThree({
  setScheduleDate,
  scheduleDate,
  submitCampaign,
  SMS_ACTIONS,
  isSmsCampaignEditable,
}) {
  const coeff = 1000 * 60 * 5;
  const date = new Date();
  const today = new Date(Math.round(date.getTime() / coeff) * coeff);

  const calculateMinTime = (selectedDate) => {
    if (moment(selectedDate).isSame(moment(date), "day")) {
      return moment(today).toDate();
    }
    return moment(date).startOf("day").toDate();
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(today));
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setDisableSaveButton(true);
      submitCampaign(SMS_ACTIONS.SCHEDULE);
    } catch (error) {
      console.log(error);
    } finally {
      setDisableSaveButton(false);
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        margin: "auto",
        height: "100%",
        marginTop: "2rem",
      }}
      onSubmit={handleSubmit}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          borderRadius: "0.875rem",
        }}
        className="sms-step-3"
      >
        <Box sx={{ padding: "2rem 3rem" }}>
          <h3 className="sms-campaing-paper-title">
            Date d'envoi de votre campagne
          </h3>
          <DatePicker
            showTimeSelect
            timeFormat="HH:mm"
            selected={scheduleDate}
            onChange={(e) => {
              setScheduleDate(e);
              setMinTime(calculateMinTime(e));
            }}
            className="pointer"
            locale="fr"
            dateFormat="HH:mm, dd-MM-yyyy"
            timeIntervals={5}
            timeCaption="time"
            minDate={today}
            minTime={minTime}
            maxTime={moment().endOf("day").toDate()}
            disabled={!isSmsCampaignEditable()}
            required
            placeholderText="Choisissez une date"
          />
        </Box>
      </Paper>
      {isSmsCampaignEditable() && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "2rem",
          }}
        >
          <CustomMuiButton
            type="submit"
            onClick={undefined}
            text="Programmer"
            minHeight={60}
            textColor="#ffffff"
            buttonBgColor="#E01755"
            buttonHoverBgColor="#AF1242"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#E01755"
            disable={disableSaveButton}
          />
        </Box>
      )}
    </form>
  );
}
