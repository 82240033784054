import React from "react";
import Dialog from "@mui/material/Dialog";

export function FormDialog({
  children,
  open,
  handleClose,
  onSubmit,
  className,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit,
          style: {
            maxWidth: "100%",
          },
        }}
      >
        {children}
      </Dialog>
    </React.Fragment>
  );
}
