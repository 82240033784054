import React from "react";
import moment from "moment";
import { matchPath } from "react-router-dom";
import { webPageTranslations } from "../common/Translations";
import ApiService from "./ApiService";
import { languagesList } from "../common/Translations";
export function formatDate(date, full) {
  if (date) {
    const newDate = new Date(date);
    const month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1;

    if (full)
      return (
        (newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        newDate.getFullYear() +
        " " +
        newDate.getHours() +
        ":" +
        (newDate.getMinutes() < 10 ? "0" : "") +
        newDate.getMinutes()
      );
    else
      return (
        (newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        newDate.getFullYear()
      );
  } else return "";
}

export function checkOne(event, newItem, itemsChecked) {
  if (event.target.checked) {
    itemsChecked.push(newItem);
    return itemsChecked;
  } else {
    return itemsChecked.filter((item) => item.id !== newItem.id);
  }
}

export const uploadPicture = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const url = `https://api.cloudinary.com/v1_1/kanguroo-event/upload`;

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", url);

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: "HTTP Error: " + xhr.status, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject("HTTP Error: " + xhr.status);
        return;
      }

      const json = JSON.parse(xhr.responseText);

      if (!json || typeof json.secure_url != "string") {
        reject("Invalid JSON: " + xhr.responseText);
        return;
      }

      resolve(json.secure_url);
    };

    xhr.onerror = () => {
      reject(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    const formData = new FormData();
    formData.append("upload_preset", "bkpnuvx9");
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  });

export function displayFollowers(guest, self) {
  if (!self.state.followersDisplayed.some((item) => item === guest.id)) {
    const followersDisplayed = self.state.followersDisplayed;
    followersDisplayed.push(guest.id);

    self.setState({
      followersDisplayed,
    });
  } else {
    const followersDisplayed = self.state.followersDisplayed.filter(
      (item) => item !== guest.id
    );

    self.setState({
      followersDisplayed,
    });
  }
}

export function toggle(self) {
  self.setState({
    modal: !self.state.modal,
  });
}

export function replaceData(template, event, packages, guest, contact, user) {
  const packagesWithSomeDataParsed = packages.map((pack) => {
    var updatedPackage = { ...pack };
    Object.keys(pack).forEach((key) => {
      if (key.includes("mail_placeholder_")) {
        let value = pack[key];
        if (
          typeof value === "string" &&
          !value.includes('"label":"Français"') // pour être sur de ne pas ré-écrire des packages qui sont bien sous le bon format
        ) {
          // Supprimer toutes les balises HTML de la valeur
          if (value.includes("<")) {
            //value = value.replace(/(<([^>]+)>)/gi, ""); // to delete htmls
            // Ceci sert pour les packages créent avant la fonctionnalité multi-langues,
            // on supprime les balises htmls et on met le texte qui précédé dans les languageslist
            updatedPackage[key] = languagesList.map((lang) => {
              return {
                ...lang,
                value: value,
              };
            });
          } else if (value === null || value.includes("null")) {
            // Ceci sert pour les packages créent avant la fonctionnalité multi-langues,
            // on supprime remplace ce qui est null par le multi-langues
            updatedPackage[key] = languagesList;
          }
        } else {
          updatedPackage[key] = JSON.parse(value);
        }
      }
    });
    return updatedPackage;
  });

  const pack = guest
    ? packagesWithSomeDataParsed.find((p) => p.id === guest.package_id)
    : "";

  let html = template.body ? template.body : "";
  let data = {
    "data:contact.company_function": contact
      ? contact.company_function
        ? contact.company_function
        : ""
      : "",
    "data:contact.company_service": contact
      ? contact.company_service
        ? contact.company_service
        : ""
      : "",
    "data:contact.picture_url": contact
      ? contact.picture_url
        ? contact.picture_url
        : ""
      : "",
    "data:contact.company_name": contact
      ? contact.company_name
        ? contact.company_name
        : ""
      : "",
    "data:access_token": contact
      ? contact.access_token
        ? contact.access_token
        : ""
      : "",
    "data:user.lastname": user ? (user.lastname ? user.lastname : "") : "",
    "data:user.firstname": user ? (user.firstname ? user.firstname : "") : "",
    "data:delegataire.invitation": user
      ? user.inviting_person
        ? user.inviting_person
        : ""
      : "",
    "data:delegataire.logo": user
      ? user.logo
        ? '<img src="https://res.cloudinary.com/kanguroo-event/image/upload/w_160/' +
          user.logo +
          '.png"/>'
        : ""
      : "",
    "data:delegataire.contact": user
      ? user.contact_informations
        ? user.contact_informations
        : ""
      : "",
    "data:guest.id": guest ? (guest.id ? guest.id : "") : "",
    "data:contact.mail": guest ? (guest.email ? guest.email : "") : "",
    "data:contact.gender": guest ? (guest.gender ? guest.gender : "") : "",
    "data:contact.firstname": guest
      ? guest.firstname
        ? guest.firstname
        : ""
      : "",
    "data:contact.lastname": guest
      ? guest.lastname
        ? guest.lastname
        : ""
      : "",
    "data:contact.mobile": guest ? (guest.mobile ? guest.mobile : "") : "",
    "data:contact.phone": guest ? (guest.phone ? guest.phone : "") : "",
    "data:contact.address": guest ? (guest.address ? guest.address : "") : "",
    "data:contact.address_more": guest
      ? guest.address_more
        ? guest.address_more
        : ""
      : "",
    "data:contact.zip_code": guest
      ? guest.zip_code
        ? guest.zip_code
        : ""
      : "",
    "data:contact.city": guest ? (guest.city ? guest.city : "") : "",
    "data:contact.country": guest ? (guest.country ? guest.country : "") : "",
    "data:max_invitation": guest
      ? guest.max_invitation
        ? guest.max_invitation + 1
        : 1
      : "x",
    "data:evenement.id": guest ? (event ? (event.id ? event.id : "") : "") : "",
    "data:evenement.start_date": guest
      ? event
        ? event.start_date
          ? formatDate(event.start_date, true)
          : ""
        : ""
      : "",
    "data:evenement.end_date": guest
      ? event
        ? event.end_date
          ? formatDate(event.end_date, true)
          : ""
        : ""
      : "",
    "data:evenement.name": guest
      ? event
        ? event.data
          ? "name" in event.data && event.data.name
            ? event.data.name
            : ""
          : ""
        : ""
      : "",
    "data:evenement.place": guest
      ? event
        ? event.data
          ? "LocationName" in event.data && event.data.LocationName
            ? event.data.LocationName
            : ""
          : ""
        : ""
      : "",
    "data:mail.sender_name": guest
      ? event
        ? event.mail_sender_name
          ? event.mail_sender_name
          : ""
        : ""
      : "",
    "data:mail.sender_address": guest
      ? event
        ? event.mail_sender_address
          ? event.mail_sender_address
          : ""
        : ""
      : "",
    "data:package.name": guest
      ? pack
        ? pack.name
          ? pack.name
          : ""
        : ""
      : "",
    "data:package.placeholder_1": guest
      ? pack
        ? pack.mail_placeholder_1
          ? pack.mail_placeholder_1.filter(
              (lang) => lang.code === contact.languages
            )[0].value
          : ""
        : ""
      : "",
    "data:package.placeholder_2": guest
      ? pack
        ? pack.mail_placeholder_2
          ? pack.mail_placeholder_2.filter(
              (lang) => lang.code === contact.languages
            )[0].value
          : ""
        : ""
      : "",
    "data:package.placeholder_3": guest
      ? pack
        ? pack.mail_placeholder_3
          ? pack.mail_placeholder_3.filter(
              (lang) => lang.code === contact.languages
            )[0].value
          : ""
        : ""
      : "",
    "data:package.placeholder_4": guest
      ? pack
        ? pack.mail_placeholder_4
          ? pack.mail_placeholder_4.filter(
              (lang) => lang.code === contact.languages
            )[0].value
          : ""
        : ""
      : "",
    "data:package.placeholder_5": guest
      ? pack
        ? pack.mail_placeholder_5
          ? pack.mail_placeholder_5.filter(
              (lang) => lang.code === contact.languages
            )[0].value
          : ""
        : ""
      : "",
  };
  for (var [key, value] of Object.entries(data)) {
    if (key.includes("placeholder")) {
      for (const [key2, value2] of Object.entries(data)) {
        value = replaceAll(value, key2, value2);
      }
    }
    html = replaceAll(html, key, value);
  }
  return html;
}

function replaceAll(html, key, value) {
  return html.split("[[" + key + "]]").join(value);
}

export async function getBase64ImageFromUrl(imageUrl) {
  if (imageUrl) {
    const res = await fetch(imageUrl);
    console.log("imageUrl", imageUrl);
    console.log("res", res);
    const blob = await res.blob();
    console.log("blob", blob);

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}

export function filterElements(event, name, self, doFilter) {
  let value = null;

  if (event) {
    if (event.target) {
      value = event.target.value;
    } else if (event.value) {
      value = event.value;
    } else {
      value = event;
    }
  }

  self.setState(
    {
      [name]: value,
    },
    () => {
      const itemFilterlength = self.state[name].length;

      if (doFilter || itemFilterlength > 3 || itemFilterlength === 0) {
        self.filter();
      }
    }
  );
}

export function changePage(page, self) {
  self.setState({ currentPage: page });
}

export function handleChange(event, self) {
  self.setState({ [event.target.name]: event.target.value });
}

export const responsesOptions = [
  {
    label: "OUI",
    value: "willJoin",
  },
  {
    label: "NON",
    value: "willNotJoin",
  },
  {
    label: "NON REPONDU",
    value: "waiting",
  },
  {
    label: "EN ATTENTE DE VALIDATION",
    value: "pending",
  },
];

export const documentsOptions = [
  {
    label: "OUI",
    value: "hasDocument",
  },
  {
    label: "NON",
    value: "hasntDocument",
  },
];

export function findLabelAndColor(
  contact,
  code,
  eventResponses,
  deleContainer
) {
  if (
    !contact.is_validated &&
    eventResponses.some((reponse) => reponse.guest_id === contact.guestId)
  ) {
    return {
      label: webPageTranslations[code].pending,
      backgroundColor: "#FFF79A",
    };
  } else if (contact.participate) {
    return {
      label: webPageTranslations[code].yes,
      backgroundColor: "#B3E9E9",
    };
  } else if (
    eventResponses.some((reponse) => reponse.guest_id === contact.guestId)
  ) {
    return {
      label: webPageTranslations[code].no,
      backgroundColor: "#FEC784",
    };
  } else
    return {
      label: deleContainer
        ? webPageTranslations[code].hyphen
        : webPageTranslations[code].not_answered,
      backgroundColor: "#FFFFFF",
    };
}

export const numberPerPage = 20;

export const delegataireId = "hf6dPL9be78!";
export const comptableId = "gP75hje!09";
export const adminId = "g8uJ?deO98";

export const roles = [
  { name: "Admin", id: adminId, code: "admin" },
  { name: "Délégataire", id: delegataireId, code: "delegataire" },
  { name: "Comptable", id: comptableId, code: "comptable" },
];

export const version = 0.2;

export function guestMode() {
  if (window.location.href.includes("account/admin")) {
    return false;
  }
  return (
    window.location.href.includes("reponse") ||
    window.location.href.includes("account") ||
    window.location.href.includes("programme") ||
    window.location.href.includes("download-passport") ||
    window.location.href.includes("download-invoice")
  );
}

export function clearLocalStorage() {
  localStorage.removeItem("user_role");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user");
  localStorage.removeItem("component");
  localStorage.removeItem("evenement");
  localStorage.removeItem("user_logo");
  localStorage.removeItem("token");
  localStorage.removeItem("invitation_token");
  localStorage.removeItem("checkin_list_position");
  localStorage.setItem("version", version);
}

export function checkVersion() {
  const localVersion = Number(localStorage.getItem("version"));

  if (localVersion < version) {
    clearLocalStorage();

    return false;
  } else {
    return true;
  }
}

export function checkRole(role) {
  const id = roles.find((item) => role === item.code).id;

  let result = true;

  if (!checkVersion()) {
    clearLocalStorage();
    window.location.replace("/");
    result = false;
  }

  if (localStorage.getItem("user_role") !== id) {
    const match = matchPath(window.location.pathname, {
      path: "/delegataires/:userId",
      strict: false,
    });
    if (localStorage.getItem("user_role") === adminId && match) {
      return null;
    }
    switch (localStorage.getItem("user_role")) {
      case delegataireId:
        window.location.replace("/delegataires");
        break;
      case adminId:
        window.location.replace("/admin/");
        break;
      default:
        window.location.replace("/");
        break;
    }

    result = false;
  }

  return result;
}

export function checkRoleWithoutReplace(role) {
  const id = roles.find((item) => role === item.code).id;

  let result = true;

  if (!checkVersion()) {
    clearLocalStorage();
    window.location.replace("/");
    result = false;
  }

  if (localStorage.getItem("user_role") !== id) {
    const match = matchPath(window.location.pathname, {
      path: "/delegataires/:userId",
      strict: false,
    });
    if (localStorage.getItem("user_role") === adminId && match) {
      return null;
    }
    result = false;
  }

  return result;
}

export const convertStylesStringToObject = (stringStyles) =>
  typeof stringStyles === "string"
    ? stringStyles.split(";").reduce((acc, style) => {
        const colonPosition = style.indexOf(":");

        if (colonPosition === -1) {
          return acc;
        }

        const camelCaseProperty = style
            .substr(0, colonPosition)
            .trim()
            .replace(/^-ms-/, "ms-")
            .replace(/-./g, (c) => c.substr(1).toUpperCase()),
          value = style.substr(colonPosition + 1).trim();

        return value ? { ...acc, [camelCaseProperty]: value } : acc;
      }, {})
    : {};
export function isExist(arg) {
  try {
    return arg();
  } catch (e) {
    return false;
  }
}
export const checkIfAccessPage = async (
  contact,
  eventId,
  event,
  guest,
  fromAdmin
) => {
  const isAccess = {
    access: true,
    reason: "",
  };
  const isExpire = checkDate(contact);
  if (!fromAdmin && (!event || event.is_archived)) {
    isAccess.access = false;
    isAccess.reason = "eventArchived";
  } else if (contact && !contact.active) {
    isAccess.access = false;
    isAccess.reason = "contactInactive";
  } else if (isExpire) {
    isAccess.access = false;
    isAccess.reason = "contactExpire";
  } else if (eventId && !isExpire) {
    if (guest) {
      const guests = await getGuestsByEvenement(eventId);
      const find = guests.find((g) => g.id === guest.id);
      if (!find) {
        isAccess.access = false;
        isAccess.reason = "contactNotInvited";
      }
    }
  }
  // if (contact && contact.active) {
  //   isAccess.access = true;
  //   isAccess.reason = "contactActive";
  // }
  return isAccess;
};

export const checkNotAccess = async (
  errorsWorkspaces,
  contact,
  eventId,
  event,
  guest,
  fromAdmin
) => {
  if (errorsWorkspaces) {
    const isAccess = await checkIfAccessPage(
      contact,
      eventId,
      event,
      guest ? guest : null,
      fromAdmin
    );

    if (!isAccess.access) {
      const findError = errorsWorkspaces.find(
        (error) => error.type === isAccess.reason
      );
      if (findError) {
        const contents = JSON.parse(findError.content);
        const language = event
          ? event.data
            ? event.data.default_language
              ? event.data.default_language
              : "FR"
            : "FR"
          : "FR";
        const langError = contents.find((cont) => cont.code === language);
        const content = langError.value;
        isAccess.content = content;
        return isAccess;
      }
    }
  }
  return {
    access: true,
    reason: "",
  };
};

const getGuestsByEvenement = async (eventId) => {
  const result = await ApiService.request(
    {},
    "guests/byevenement/" + eventId,
    "get"
  );
  return result;
};

const checkDate = (contact) => {
  if (contact) {
    const expiration = contact.expiration_date;
    if (expiration) {
      const isExpire = moment(expiration).isBefore();
      if (expiration === "1970-01-01") {
        return false;
      }
      return isExpire;
    }
  }
  return false;
};

export const returnErrorHtml = (content) => {
  return (
    <div
      className="centerColumn"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export function locationPush(page) {
  return window.location.replace("/" + page);
}

export function dateFormat(date) {
  return moment(date).format("DD/MM/YYYY à H:mm");
}

export function colorByStatus(status) {
  const obj = {
    draft: "#A2A2A2",
    waiting: "#17A7A8",
    in_progress: "#6b11ca",
    sent: "#E01755",
    error: "#FAA047",
    partial: "#83c90a",
  };
  return obj[status];
}

export function getStatusCampaign(campaign) {
  let status = "";
  switch (true) {
    case campaign.draft:
      status = "draft";
      break;
    case campaign.draft && campaign.sent:
      status = "waiting";
      break;
    case !campaign.draft &&
      !campaign.error &&
      !campaign.in_progress &&
      !campaign.sent:
      status = "waiting";
      break;
    case campaign.in_progress && !campaign.error && !campaign.sent:
      status = "in_progress";
      break;
    case campaign.error && !campaign.in_progress && campaign.sent:
      status = "error";
      break;
    case campaign.error && !campaign.in_progress && !campaign.sent:
      status = "error";
      break;
    case !campaign.draft &&
      campaign.sent &&
      !campaign.error &&
      !campaign.in_progress:
      status = "sent";
      break;
    default:
      status = "waiting";
      break;
  }

  const obj = {
    draft: { key: "draft", label: "Brouillon" },
    waiting: { key: "waiting", label: "En attente d'envoi" },
    in_progress: { key: "in_progress", label: "En cours d'envoi" },
    sent: { key: "sent", label: "Envoyé" },
    error: { key: "error", label: "Erreur d'envoi" },
  };
  return obj[status];
}

export function sortedByDates(array, key) {
  return array && key
    ? array.sort((a, b) => moment(b[key]) - moment(a[key]))
    : [];
}

export const capitalizeFirstLetter = (string) => {
  if (string) {
    const str = string.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export const makeUniqArrayByKey = (array, key) => {
  const uniqueData = [
    ...array.reduce((map, obj) => map.set(obj[key], obj), new Map()).values(),
  ];
  return uniqueData;
};

export function isOdd(num) {
  return !!(num % 2);
}

export const removeElementFromArray = (array, index) => {
  if (index > -1) {
    return array.filter((element, idx) => idx !== index);
  }
  return false;
};

export function chunk(array, number = 100) {
  return array
    ? [...Array(Math.ceil(array.length / number))].map((_, index) =>
        array.slice(number * index, number + number * index)
      )
    : [];
}

export const accented = {
  a: ["à", "á", "â", "ã", "ä"],
  e: ["è", "é", "ê", "ë"],
  i: ["ì", "í", "î", "ï"],
  o: ["ò", "ó", "ô", "õ", "ö"],
  u: ["ù", "ú", "û", "ü"],
  y: ["ý", "ÿ"],
  n: ["ñ"],
};

export const accentedArrayOfString = (baseString) => {
  if (baseString) {
    const arrayOfString = [];
    for (let letter of baseString) {
      if (accented[letter]) {
        accented[letter].forEach((accent) => {
          const stringAccented = baseString.replace(letter, accent);
          if (stringAccented) {
            arrayOfString.push(stringAccented.toLowerCase().trim());
          }
        });
      }
    }
    arrayOfString.push(baseString);
    return arrayOfString;
  } else {
    return [];
  }
};
const userAgent = navigator.userAgent.toLowerCase();
export const isMobile = /iPhone|Android/i.test(navigator.userAgent);
export const isTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );

export const isEventPaymentConfigOk = (
  isFree,
  stripePublicKey,
  stripeSecretKey,
  eventComponents
) => {
  if (
    !JSON.parse(isFree) &&
    (stripePublicKey === null ||
      stripePublicKey === "" ||
      stripePublicKey === "undefined" ||
      stripeSecretKey === null ||
      stripeSecretKey === "" ||
      stripeSecretKey === "undefined")
  ) {
    return false;
  }

  if (JSON.parse(isFree)) {
    const payingComponent = eventComponents.find(
      (component) => !JSON.parse(component.data.isFree)
    );
    if (payingComponent) return false;
  }

  return true;
};

export const sortByLastName = () => {
  return function (a, b) {
    // console.log(a.user.lastname)
    var valueA = a.user.lastname.toUpperCase(); // Ignorer la casse
    var valueB = b.user.lastname.toUpperCase();

    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  };
};

export const getComponentGuests = (
  delegataireUserId,
  component,
  eventResponses
) => {
  let guestsCount = 0;

  eventResponses.forEach((usersResponses) => {
    if (
      usersResponses &&
      usersResponses.responses &&
      usersResponses.responses.length > 0
    ) {
      usersResponses.responses.forEach((componentReponse) => {
        if (usersResponses.guest) {
          if (
            Number(componentReponse.id) === Number(component.id) &&
            Number(usersResponses.guest.user_id) ===
              Number(delegataireUserId) &&
            Number(componentReponse.key) === 0 // only count positive responses
          ) {
            guestsCount += 1;
          } else if (
            delegataireUserId === "total" &&
            Number(componentReponse.id) === Number(component.id) &&
            Number(componentReponse.key) === 0 &&
            usersResponses.guest.user_id === null
          ) {
            guestsCount += 1;
          }
        }
      });
    }
  });
  return guestsCount;
};

export const getComponentNameAssociatedToGuest = (
  guestId,
  components,
  eventResponses
) => {
  const associatedComponents = [];
  // Find response associate to guestId
  const associatedResponse = eventResponses.find((rep) => {
    return rep.guest_id === guestId;
  });
  // Filter components that have been responded to by the guest
  if (associatedResponse && associatedResponse.responses) {
    associatedResponse.responses.forEach((componentResponse) => {
      if (componentResponse && Number(componentResponse.key) === 0) {
        const associatedComponent = components.find((comp) => {
          return Number(comp.id) === Number(componentResponse.id);
        });
        if (associatedComponent) {
          associatedComponents.push(associatedComponent);
        }
      }
    });
    // Sort components by startDate
    associatedComponents.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
  }
  return associatedComponents;
};
