import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { styled } from "@mui/material/styles";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  colorByStatus,
  dateFormat,
  sortedByDates,
  isEventPaymentConfigOk,
} from "../../../services/Utils";
import GuestsErrorModal from "../../../common/GuestsErrorModal";
import { CustomTable } from "../../../common/table_component/CustomTable";
import TableRow from "@mui/material/TableRow";
import { CustomMuiButton } from "../components/CustomMuiButton";
import { StatBoxWithTitle } from "../components/StatBoxWithTitle";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "red",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "#e1dfdf",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SmsList = ({ match, components }) => {
  const eventId = match.params.eventid;
  const [smsList, setSmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [smsModal, setSmsModal] = useState("");
  const [invoiceSetting, setInvoiceSetting] = useState([]);
  const [event, setEvent] = useState(null);
  const [smsStat, setSmsStat] = useState(null);

  const loadData = async () => {
    try {
      setIsLoading(true);

      const resEvent = await ApiService.awaitRequest(
        {},
        "evenements/" + eventId,
        "get"
      ).then((data) => {
        setEvent(data);
        return data;
      });

      await ApiService.awaitRequest(
        {},
        "sms/global/stats/" + eventId,
        "get"
      ).then((data) => {
        if (data) {
          setSmsStat(data);
        }
        return data;
      });

      await ApiService.request(
        {},
        "sms/byevenement/" + match.params.eventid,
        "get"
      ).then((data) => {
        setSmsList(data);
      });

      if (resEvent && resEvent.data.free === "false") {
        await ApiService.request(
          {},
          "invoices/settings/" + resEvent.children_entity_id,
          "get"
        ).then((data) => {
          setInvoiceSetting(data);
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la récupération des données");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getStatusSms = (status) => {
    const obj = {
      draft: { key: "draft", label: "Brouillon" },
      waiting: { key: "waiting", label: "Programmé" },
      in_progress: { key: "in_progress", label: "En cours d'envoi" },
      send_now: { key: "in_progress", label: "En cours d'envoi" },
      sent: { key: "sent", label: "Envoyé" },
      error: { key: "error", label: "Erreur" },
      partial: { key: "partial", label: "Partiellement Envoyé" },
    };
    return obj[status];
  };

  const getDeliveredSms = (id) => {
    if (smsStat && smsStat.statsList) {
      const smsFound = smsStat.statsList.find((sms) => sms.id === id);
      if (smsFound) {
        return smsFound.stats.delivered || 0;
      }
    }
    return null;
  };

  const openErrorModal = (sms) => {
    setSmsModal(sms ? (!errorModal ? sms : "") : "");
    setErrorModal((prev) => !prev);
  };

  const deleteSms = async (id) => {
    setIsLoading(true);

    await ApiService.request({}, "sms/" + id, "delete").then(() => {
      loadData();
    });
  };

  const filters = sortedByDates([...smsList], "schedule_date");

  const sortedSMSCampaigns = filters.length > 0 ? filters : smsList; //!

  const populateRows = (isSelected, handleClick, visibleRows) => {
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <StyledTableRow
          key={row.id}
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
          sx={{ cursor: "pointer" }}
        >
          <StyledTableCell component="th" id={labelId} scope="row">
            {row.id}
          </StyledTableCell>
          <StyledTableCell align="left">{row.name}</StyledTableCell>
          <StyledTableCell align="left">{row.date}</StyledTableCell>
          <StyledTableCell align="left">
            <p
              style={{
                color: "#ffffff",
                backgroundColor: colorByStatus(row.status.key),
                padding: "0.5rem 1rem",
                borderRadius: "50px",
                whiteSpace: "nowrap",
                margin: "auto",
                textAlign: "center",
              }}
            >
              {row.status.label}
            </p>
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.delivered === null ? "-" : row.delivered}
          </StyledTableCell>
          <StyledTableCell align="left">{row.nbOfContacts}</StyledTableCell>
          <StyledTableCell align="left">{row.actions}</StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  const createSMSTableRowsData = (data) => {
    return data.map((sms, index) => {
      const delivered = getDeliveredSms(sms.id);

      let status = "";
      if (sms.status === "error" && delivered > 0) {
        status = getStatusSms("partial");
      } else {
        status = getStatusSms(sms.status);
      }

      return {
        id: index + 1,
        name: sms.title,
        date: dateFormat(sms.schedule_date),
        status,
        delivered,
        nbOfContacts: sms.nbContact,
        actions: (
          <>
            &nbsp;&nbsp;&nbsp;
            <Link
              to={
                "/admin/events/edit/" +
                match.params.eventid +
                "/sms/edit/" +
                sms.id
              }
            >
              <FontAwesomeIcon icon={faEdit} color="rgba(0, 0, 0, 0.87)" />
            </Link>
            {sms.error_data && (
              <>
                &nbsp;&nbsp;&nbsp;
                <Link to={"#"}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color={"#d81b60"}
                    onClick={() => openErrorModal(sms)}
                  />
                </Link>
              </>
            )}
            &nbsp;&nbsp;&nbsp;
            <span
              className="pointer"
              onClick={() => {
                if (
                  window.confirm(
                    "Etes vous sûr de supprimer cette campagne sms?"
                  )
                )
                  deleteSms(sms.id);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </>
        ),
      };
    });
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      {!isEventPaymentConfigOk(
        event.data.free,
        invoiceSetting.stripePublicKey,
        invoiceSetting.stripeSecretKey,
        components
      ) ? (
        `L'évènement est payant, vous devez mettre en place une ou des solutions de paiements pour l'Entité de l'évènement pour accèder aux campagnes`
      ) : (
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className={"col-md-12"}>
              <h2>Campagnes SMS</h2>
            </div>
            <div
              className="col-md-9 d-flex justify-content-around"
              style={{ gap: "1rem" }}
            >
              <StatBoxWithTitle
                title="Total SMS"
                stat={
                  smsStat && smsStat.globalStats
                    ? smsStat.globalStats.total
                    : ""
                }
              />
              <StatBoxWithTitle
                title="Solde CRÉDITS"
                stat={
                  smsStat && smsStat.globalStats
                    ? smsStat.globalStats.remaingCredits
                    : ""
                }
              />
            </div>
            <div className="col-md-3">
              <CustomMuiButton
                type="button"
                onClick={() => {
                  window.location.assign(
                    "/admin/events/edit/" + eventId + "/sms/edit/"
                  );
                }}
                text={
                  <>
                    Nouvelle <br />
                    Campagne SMS
                  </>
                }
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#E01755"
                buttonHoverBgColor="#AF1242"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#E01755"
              />
            </div>
          </div>
          <br />

          <CustomTable
            data={sortedSMSCampaigns}
            createTableRows={createSMSTableRowsData}
            selected={[]}
            setSelected={() => null}
            populateRows={populateRows}
            headCells={headCells}
            activateCheckbox={false}
            // headCellesNoWrap={true}
          />
          {errorModal && (
            <GuestsErrorModal
              open={errorModal}
              setModal={openErrorModal}
              campaign={smsModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nom",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date d'envoi",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Statut",
  },
  {
    id: "delivered",
    numeric: false,
    disablePadding: false,
    label: "Délivrés",
  },
  {
    id: "nbOfContacts",
    numeric: false,
    disablePadding: false,
    label: "Nb de Contacts",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];
